export function StackedAreaChartIcon() {
  return (
    <svg aria-hidden="true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 14.25H0C0 14.6642 0.335786 15 0.75 15L0.75 14.25ZM0.75 13.25L0.281479 12.6643C0.103567 12.8067 0 13.0222 0 13.25H0.75ZM5.75 9.25L6.08541 8.57918L5.6562 8.36457L5.28148 8.66435L5.75 9.25ZM9.75 11.25L9.41459 11.9208C9.67891 12.053 9.99621 12.0176 10.2249 11.8305L9.75 11.25ZM15.25 6.75H16C16 6.46021 15.833 6.19635 15.5712 6.07225C15.3093 5.94815 14.9994 5.98602 14.7751 6.16953L15.25 6.75ZM15.25 14.25V15C15.6642 15 16 14.6642 16 14.25H15.25ZM1.5 14.25V13.25H0V14.25H1.5ZM1.21852 13.8357L6.21852 9.83565L5.28148 8.66435L0.281479 12.6643L1.21852 13.8357ZM5.41459 9.92082L9.41459 11.9208L10.0854 10.5792L6.08541 8.57918L5.41459 9.92082ZM10.2249 11.8305L15.7249 7.33047L14.7751 6.16953L9.27507 10.6695L10.2249 11.8305ZM14.5 6.75V14.25H16V6.75H14.5ZM15.25 13.5H0.75V15H15.25V13.5Z"
        fill="currentColor"
      />
      <path
        d="M0.281479 8.91435C-0.0419677 9.17311 -0.0944088 9.64507 0.164348 9.96852C0.423106 10.292 0.895075 10.3444 1.21852 10.0857L0.281479 8.91435ZM5.75 5.5L6.08541 4.82918C5.82376 4.69835 5.50991 4.7316 5.28148 4.91435L5.75 5.5ZM9.75 7.5L9.41459 8.17082L9.87424 8.40065L10.2545 8.05496L9.75 7.5ZM15.7545 3.05496C16.061 2.77632 16.0836 2.30199 15.805 1.9955C15.5263 1.689 15.052 1.66641 14.7455 1.94504L15.7545 3.05496ZM1.21852 10.0857L6.21852 6.08565L5.28148 4.91435L0.281479 8.91435L1.21852 10.0857ZM5.41459 6.17082L9.41459 8.17082L10.0854 6.82918L6.08541 4.82918L5.41459 6.17082ZM10.2545 8.05496L15.7545 3.05496L14.7455 1.94504L9.2455 6.94504L10.2545 8.05496Z"
        fill="currentColor"
      />
    </svg>
  )
}

try{ StackedAreaChartIcon.displayName ||= 'StackedAreaChartIcon' } catch {}