export const SORTED_BY_COLUMN_ID_KEY = 'sortedBy[columnId]'
export const SORTED_BY_COLUMN_DIRECTION_KEY = 'sortedBy[direction]'
export const FILTER_QUERY_PARAM = 'filterQuery'
export const VIEW_TYPE_PARAM = 'layout'
export const HORIZONTAL_GROUPED_BY_COLUMN_KEY = 'groupedBy[columnId]'
export const VERTICAL_GROUPED_BY_COLUMN_KEY = 'verticalGroupedBy[columnId]'
export const HORIZONTAL_GROUPED_BY_COLUMN_VALUE_KEY = 'groupedBy[value]'
export const VERTICAL_GROUPED_BY_COLUMN_VALUE_KEY = 'verticalGroupedBy[value]'
export const VISIBLE_FIELDS_PARAM = 'visibleFields'
export const HIDE_ITEMS_COUNT_PARAM = 'hideItemsCount'
export const SUM_FIELDS_PARAM = 'sumFields'
export const SLICE_BY_COLUMN_ID_KEY = 'sliceBy[columnId]'
export const SLICE_VALUE_KEY = 'sliceBy[value]'
export const PERIOD_PARAM = 'period'
export const START_DATE_PARAM = 'start_date'
export const END_DATE_PARAM = 'end_date'

export const X_AXIS_GROUP_BY_PARAM = 'xAxis[groupBy]'
export const X_AXIS_DATASOURCE_COLUMN_PARAM = 'xAxis[dataSource][column]'
export const Y_AXIS_AGGREGATE_COLUMNS_PARAM = 'yAxis[aggregate][columns]'
export const Y_AXIS_AGGREGATE_OPERATION_PARAM = 'yAxis[aggregate][operation]'

export const PER_PAGE_PARAM = 'per_page'

export const PANE_PARAM = 'pane'
export const ITEM_ID_PARAM = 'itemId'
export const NWO_REFERENCE_PARAM = 'issue'
export const STATUS_UPDATE_ID_PARAM = 'statusUpdateId'

export const AFTER_PARAM = 'after'
export const SECONDARY_AFTER_PARAM = 'secondaryAfter'
export const BEFORE_PARAM = 'before'
export const FIRST_PARAM = 'first'
export const Q_PARAM = 'q'
export const SCOPE_PARAM = 'scope'
export const FIELD_IDS_PARAM = 'fieldIds'
export const NEW_MWL_RESPONSE = 'newMwlResponse'
