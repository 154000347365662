import {testIdProps} from '@github-ui/test-id-props'

import {ItemType} from '../../../api/memex-items/item-type'
import {type ColumnValue, isEmpty, isLoading} from '../../../models/column-value'
import type {MemexItemModel} from '../../../models/memex-item-model'
import {PillPlaceholder} from '../../common/placeholders'
import {BaseCell} from '../cells/base-cell'
import {UserGroup, type UserGroupItem} from '../cells/user-group'
import {useRecordCellRenderer} from '../performance-measurements'
import {withCellRenderer} from './cell-renderer'

type Props = Readonly<{
  currentValue: ColumnValue<Array<UserGroupItem>>
  model: MemexItemModel
}>

export const LoadingReviewersCell = () => (
  <BaseCell>
    <PillPlaceholder minWidth={30} maxWidth={60} {...testIdProps('placeholder')} />
  </BaseCell>
)

export const ReviewersRenderer = withCellRenderer<Props>(function ReviewersRenderer({currentValue, model}) {
  useRecordCellRenderer('ReviewersRenderer', model.id)

  if (isLoading(currentValue)) {
    return <LoadingReviewersCell />
  }

  if (model.contentType === ItemType.DraftIssue || isEmpty(currentValue)) {
    return null
  }

  return (
    <BaseCell>
      <UserGroup users={currentValue.value} />
    </BaseCell>
  )
})

ReviewersRenderer.displayName = 'ReviewersRenderer'

try{ LoadingReviewersCell.displayName ||= 'LoadingReviewersCell' } catch {}